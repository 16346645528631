import React from 'react';
import Layout from '../containers/gatsby/layout';
import CustomiseContainer from '../containers/bookingStep2/Customise/CustomiseContainer';

const JobDescriptionPage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <CustomiseContainer />
  </Layout>
);

export default JobDescriptionPage;
